import "../../App.css";
import React from "react";
import SignIn from "../SignIn";

function SignInPage() {
  return (
    <>
      <SignIn></SignIn>
    </>
  );
}

export default SignInPage;
